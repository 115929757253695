import React, { Component, Suspense, lazy } from 'react';
import './scss/App.scss';
import { Switch, Route } from 'react-router';
import NotFound404 from './containers/App/404';
import axios from 'axios';
import pass from './medias/assets/password.txt';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const Home = lazy(() => import('./containers/Home'));
const Contact = lazy(() => import('./containers/Contact'));


class App extends Component {
    state = {
      isLogged: true
    }

    handleLogin = password => {
      axios.get(pass)
        .then(response => {
          const encoded = HmacSHA256(password, '7d792cf6a48ebd6eb9c5c33a1e3afac250940274').toString()
          if (response.data === encoded) {
            this.setState({isLogged: true})
          }
        })
    }

    render() {
        return (
          <div className="App App-container">
                <>
                  <Suspense fallback={<div id="preloader" />}>
                      <Switch>
                          <Route key="home" exact path='/' component={Home}/>
                          <Route key="contact" exact path='/contact' component={Contact}/>
                          <Route path="*" component={NotFound404} status={404}/>
                      </Switch>
                  </Suspense>
                </>
          </div>
    );
  }
}

export default App;